







































































































































































































































































































































































































































































































































































































































































































@import "../../../../assets/css/exper/experiDetail";

.tabsCage {
  margin-left: 0;
}

.dialog-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.dialog-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

/* .rowClassColor {
  color: var(--FontColor);
  background: var(--bgColor);
} */

.setMonitoring {
  color: #FFAB57;
  cursor: pointer;
}

.sxt-icon {
  width: 16px;
  height: 16px;
}

/* 内嵌表格 */
.table-nest {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 0 -12px;
}

.table-nest .table-row {
  width: 210px;
  border-bottom: 1px solid #D5EAFF;
  line-height: 40px;
  padding: 0 8px;
  display: flex;
}

.table-nest .nest-flex {
  display: flex;
  flex-direction: row;

  div {
    /* width:50%; */
    line-height: 40px;
    padding: 0 8px;

    input {
      width: 100%;
    }
  }

}

.stepContent .smallWidth {
  width: auto;
}

.nest-flex .nest-line {
  border-right: 1px solid #D5EAFF;
}

.remark-content {
  padding: 10px;
}

.remark-content .list-row {
  /* height: 30px; */
}

.remark-content .list-row-name {
  font-weight: bold;
  margin-right: 40px;
}

.remark-content .re-content {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  /* margin-bottom: 10px; */
}

.remark-content .remark-btn {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.re-content-list {
  max-height: 200px;
  overflow-y: auto;
  border-bottom: 1px solid rgba(187, 187, 187, 0.3);
}

.re-content-item {
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.3);
  padding: 10px;
}
